var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"iurak6NXBK_TUZoeGgkMB"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn:
        SENTRY_DSN ||
        'https://032402d2c81d4e0d8360c70c9141dd59@o170137.ingest.sentry.io/1376069',
    // Adjust this value in production, or use tracesSampler for greater control
    //tracesSampleRate: 0.2,
    enabled: process.env.NODE_ENV === 'production',
    ignoreErrors: [
        'TypeError: Failed to fetch',
        'TypeError: NetworkError when attempting to fetch resource.',
        '@mui/material/Tabs/Tabs',
        "Cannot destructure property 'scrollTop'",
        'Right side of assignment cannot be destructured',
        'Load failed',
    ],

    //debug: true
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
